// import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface LocationDataInterface{
    hostname:string;
    pathname:string;
    search:Array<string>;
}
//{hostnane:"",pathname:"",search:[]}
export class Visit {
//   public locationData$: BehaviorSubject<LocationDataInterface>;
   // public valueSubject$:BehaviorSubject<any>=new BehaviorSubject<any>(null);
//    public locationData$:BehaviorSubject<LocationDataInterface>=new BehaviorSubject<LocationDataInterface>({hostnane:"",pathname:"",search:[]});

    constructor(){
        //this.URLInfo = new BehaviorSubject<LocationDataInterface>({hostnane:"",pathname:"",search:[]});
    }
    public fromWindow():void{
        // this.URLInfo.next({
        //     hostname:window.location.hostname,
        //     pathname:window.location.pathname,
        //     search:this.parseQueryString(window.location.search)
        // });
    }

    private parseQueryString(q:string):Array<string> {
        if(q[0]=="?"){
            q=q.substring(1);
        }
        var vars = q.split('&');
        const ret=new Array<string>();
        for(let vs of vars){
            let idx = vs.indexOf("=");
            if(idx<0){
                (<any>ret)[decodeURIComponent(vs)]=null;
                continue;
            }
            if(idx===0){
                (<any>ret)[decodeURIComponent(vs.substring(1))]=null;
                continue;
            }
            if((idx+1)>=vs.length){
                (<any>ret)[decodeURIComponent(vs.substring(0,idx))]=null;
            } else {
                (<any>ret)[decodeURIComponent(vs.substring(0,idx))]=decodeURIComponent(vs.substring(idx+1));
            }
        }
        return ret;
    }
};