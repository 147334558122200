import { Visit } from "./lib/visit.class";

const config = {
    apiKey: "AIzaSyCLC2d6CH_kYgb_dGzEVfxGSSDoYwDYSGY",
    authDomain: "sirius-web-7c7cf.firebaseapp.com",
    databaseURL: "https://sirius-web-7c7cf.firebaseio.com",
    projectId: "sirius-web-7c7cf",
    storageBucket: "sirius-web-7c7cf.appspot.com",
    messagingSenderId: "218456754837"
};

const visit = new Visit();
console.log("Here we go...");

console.log("visit url info is ", visit.fromWindow());
